<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    :loading="loading"
    submit-title="Download CSV"
    title="All Wallets"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit(addresses)"
  >
    <div class="mb-5 addresses-list">
      <div
        v-for="(address, index) in addresses"
        :key="index"
        class="mb-1 flex"
      >
        <span class="mr-1 bold">{{ index + 1 }}.</span> <span>{{ address }}</span>
      </div>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    onSubmit(addresses) {
      const csvContent = addresses.map(address => [address].join(',')).join('\n');

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'addresses.csv';

      // Append the link to the body and click it to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);
    },
  },
}
</script>

<style>
.addresses-list {
  max-height: 400px;
  overflow: auto;
}
</style>
