<template>
  <div
    class="tag"
    :class="[{'tag__selected': isSelected}, {'tag__score--not-removable': score !== undefined && score !== null && !removable}]"
    :style="`border-color: ${findColorByTypeScore(score)}; background-color: ${hex2rgba(findColorByTypeScore(score), 0.25)}`"
    @click="selectTag"
  >
    <div
      v-if="score !== undefined && score !== null"
      class="mr-2 tag__score"
      :style="`background-color: ${findColorByTypeScore(score)};`"
    >
      {{ score }}
    </div>
    <div
      class="ellipsis"
      :class="[{'ml-1': score === undefined || score === null}]"
      :style="`max-width: ${maxWidthText};`"
    >
      {{ tag }}
    </div>
    <gl-icon
      v-if="removable"
      class="tag__close-icon"
      :height="16"
      name="tag-close"
      :width="16"
      @click="remove"
    />
  </div>
</template>

<script>
//Components
import GlIcon from '@/components/gl-icon'
// Utils
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";

export default {
  components: {
    GlIcon,
  },
  props: {
    tag: {
      type: String,
      default: '---',
      require: true,
    },
    score: {
      type: Number,
      default: null,
    },
    value: {
      type: [Number, String, Object],
      default: null
    },
    removable: {
      type: Boolean,
      default: false
    },
    maxWidthText: {
      type: String,
      default: '200px'
    },
  },
  data() {
    return {
       isSelected: false,
    }
  },
  methods: {
    findColorByTypeScore,
    selectTag() {
      this.isSelected = !this.isSelected
    },
    remove() {
      this.$emit('remove', this.value ? this.value : this.tag)
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
  },
}
</script>